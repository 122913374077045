<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Auth from "@/services/Auth";
import Swal from "sweetalert2";

export default {
    components: { Layout, PageHeader },
    data() {
        return {
            showLoader: false,
            error: null,
            tableData: [],
            items: [],
            title: "Application Methods",
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "version",
            isUpdating: false,
            sortDesc: true,
            isTableLoading: false,
            names: [],
            tenant: process.env.VUE_APP_TENANT,
            fields: [
                {
                    key: "app_name",
                    label: "Name",
                    visible: false,
                },
                {
                    key: "method",
                    label: "Method",
                    visible: true,
                },
                {
                    key: "status",
                    label: "Status",
                    visible: true,
                    slot: 'status'
                },
            ],
            filterInput: {
                name: '',
            },
            totalRows: 0,
            showModal: false,
            selectedRow: null,
            statusOptions: [
                { value: 0, text: "0" },
                { value: 1, text: "1" }
            ],
            loadingCheckbox: null,
        };
    },
    async mounted() {
        try {
            await this.getApplicationMethodsExtended();
            await this.getApplications();
            // await this.addApplicationMethod();
        } catch (error) {
            console.error('Error during component initialization:', error);
        }
    },
    methods: {

        async getApplications() {
            this.showLoader = true;
            try {
                const response = await Auth.getApplications();
                this.names = response.data.data;
            } catch (error) {
                this.error = error.data ? error.data.error : "An unexpected error occurred.";
            } finally {
                this.showLoader = false;
            }
        },

        async getApplicationMethodsExtended(appId) {
            this.showLoader = true;
            try {
                this.isTableLoading = true;
                const response = await Auth.getApplicationMethodsExtended(appId);
                this.tableData = response.data.data.map(item => ({
                    ...item,
                    status: Boolean(item.status)
                }));
                this.totalRows = this.tableData.length;
            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = [];
                this.totalRows = 0;
            } finally {
                this.showLoader = false;
                this.isTableLoading = false;
            }
        },


        getFilters() {
            var filter_string = '';
            if (this.filterInput.name) {
                filter_string += (this.filterInput.name) ? '&app_id=' + this.filterInput.name : "";
            }

            return filter_string = filter_string.substring(1);
        },

        async handleStatusChange(item) {
            const uniqueId = `${item.app_id}|${item.method}`;

            this.loadingCheckbox = uniqueId;

            try {
                let response;
                if (item.status) {
                    response = await Auth.addApplicationMethod(item);
                    if (response.data) {
                        this.updateRowStatus(item, true);
                    } else {
                        Swal.fire("Fail!", "Could not create the method", "warning");
                        this.updateRowStatus(item, false);
                    }
                } else {
                    response = await Auth.deleteApplicationMethod(item);
                    if (response.data) {
                        this.updateRowStatus(item, false);
                    } else {
                        Swal.fire("Fail!", "Could not delete the method", "warning");
                        this.updateRowStatus(item, true);
                    }
                }
            } catch (error) {
                const errorMessage = error.response?.data?.error_details?.error_message || error.message;
                Swal.fire("Fail!", errorMessage, "error");
                this.updateRowStatus(item, !item.status);
            } finally {
                this.loadingCheckbox = null;
            }
        },

        updateRowStatus(item, status) {
            const index = this.tableData.findIndex(
                i => i.app_id === item.app_id && i.method === item.method
            );
            if (index !== -1) {
                this.$set(this.tableData, index, {
                    ...item,
                    status: status,
                });
            }
        },


        async handleMethodChange(appId = '') {
            this.getApplicationMethodsExtended(appId);
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },

    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row" v-if="!showLoader">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-md-4 mx-auto">
                                <b-form-group label="Select Name" label-for="name" class="mb-3 text-center">
                                    <select v-model="filterInput.name" class="form-select" id="name"
                                        @change="handleMethodChange(filterInput.name)">
                                        <option value="">Select All</option>
                                        <option v-for="name in names" :key="name.app_id" :value="name.app_id">{{
                                            name.app_name }}</option>
                                    </select>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="col-sm-12 col-md-12">
                            <div class="dataTables_filter text-md-center">
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                                        class="form-control form-control-sm ms-2" style="width: 300px;"></b-form-input>
                                </label>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table :busy="isTableLoading" :items="tableData" :fields="fields" responsive="sm"
                                :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                show-empty empty-text="No Data Found" @filtered="onFiltered">
                                <template #cell(status)="row">
                                    <div class="form-check form-switch form-switch-lg" dir="ltr">
                                        <input type="checkbox" class="form-check-input"
                                            :id="'customSwitchsizelg' + row.item.app_id + '|' + row.item.method"
                                            v-model="row.item.status" @change="handleStatusChange(row.item)"
                                            :disabled="loadingCheckbox !== null && loadingCheckbox !== `${row.item.app_id}|${row.item.method}`" />
                                        <b-spinner small
                                            v-if="loadingCheckbox === `${row.item.app_id}|${row.item.method}`"
                                            class="ms-2">
                                        </b-spinner>
                                    </div>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <b-pagination v-model="currentPage" :total-rows="totalRows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center">
                <b-spinner large></b-spinner>
            </div>
        </div>
    </Layout>
</template>
